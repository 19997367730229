<template>
  <!-- 循环任务 -->
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div>
        <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="时间" prop="day">
                <el-select
                  multiple
                  v-model="addEditForm.day"
                  filterable
                  placeholder="请选择"
                  style="width: 90%"
                >
                  <el-option
                    v-for="item in planDayList"
                    :key="item.key"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12">
              <el-form-item label="花费时长" prop="duration">
                <el-input-number
                  placeholder="小时"
                  v-model="addEditForm.duration"
                  prop="budget"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="时间范围" prop="time">
                <el-time-picker
                  is-range
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="addEditForm.time"
                  format="HH:mm"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  placeholder="选择时间范围"
                ></el-time-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="休息间隔" prop="restInterval">
                <el-input-number
                  placeholder="请输入"
                  v-model="addEditForm.restInterval"
                  prop="restInterval"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>

            <el-col :md="12" :sm="12">
              <el-form-item label="等级" prop="grade">
                <el-select
                  v-model="addEditForm.grade"
                  filterable
                  placeholder="请选择"
                  style="width: 70%"
                >
                  <el-option
                    v-for="item in gradeOption"
                    :key="item.key"
                    :label="item.value"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="标题" prop="title">
                <el-input
                  placeholder="请输入"
                  v-model="addEditForm.title"
                  prop="title"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12">
              <el-form-item label="预算" prop="budget">
                <el-input-number
                  placeholder="预算"
                  v-model="addEditForm.budget"
                  prop="budget"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :md="24" :sm="24">
              <el-form-item label="标签" prop="label">
                <el-tag
                  :key="tag"
                  v-for="tag in dynamicTags"
                  closable
                  :disable-transitions="false"
                  @close="handleCloseTag(tag)"
                  >{{ tag }}</el-tag
                >
                <el-input
                  class="input-new-tag"
                  v-if="inputVisible"
                  v-model="addEditForm.label"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                ></el-input>
                <el-button
                  v-else
                  class="button-new-tag"
                  size="small"
                  @click="showInput"
                  >+ New Tag</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="24" :sm="24">
              <el-form-item label="内容" prop="content">
                <el-input
                  placeholder="请输入"
                  v-model="addEditForm.content"
                  prop="content"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="24" :sm="8">
              <el-form-item label="链接" prop="url">
                <el-input
                  placeholder="请输入url"
                  v-model="addEditForm.url"
                  prop="url"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="24" :sm="8">
              <el-form-item label="备注" prop="remark">
                <el-input
                  placeholder="请输入备注"
                  v-model="addEditForm.remark"
                  clearable
                  @clear="handleQuery()"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="状态" prop="status">
                <el-radio v-model="addEditForm.status" :label="1">正常</el-radio>
                <el-radio v-model="addEditForm.status" :label="2">失效</el-radio>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12">
              <el-form-item label="是否提醒" prop="isRemind">
                <el-checkbox
                  v-model="addEditForm.isRemind"
                  :checked="addEditForm.isRemind === 1 ? true : false"
                  :true-label="1"
                  :false-label="2"
                  label="是"
                  border
                  size="medium"
                  style="margin: 10px 30px  40px 30px"
                ></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <el-col :md="12" :sm="12">
              <el-form-item label="详情步骤" prop="isDataInfo">
                <el-checkbox
                  v-model="addEditForm.isDataInfo"
                  :checked="addEditForm.isDataInfo === 1 ? true : false"
                  :true-label="1"
                  :false-label="2"
                  label="是"
                  border
                  size="medium"
                  style="margin: 10px 30px  40px 30px"
                  @change="isDataInfoButton()"
                ></el-checkbox>
              </el-form-item>
            </el-col>
            <el-col :md="12" :sm="12" v-if="addEditForm.isDataInfo != 1">
              <el-form-item label="进度" prop="progressRate">
                <customProgress
                  ref="cusChild"
                  :percent="addEditForm.progressRate"
                  bgColor="#ddd"
                  progressColor="#409EFF"
                  :showPerText="true"
                  :max="100"
                  @percentChange="onPercentChange"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-button v-if="this.dataInfoShow" @click="addDataInfo"
          >新增</el-button
        >
        <el-table
          v-if="this.dataInfoShow"
          height="200"
          :data="dataInfoList"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            :index="indexMethod"
            fixed
            type="index"
            width="50"
          ></el-table-column>
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            width="100"
            fixed
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click.native.prevent="deleteRow(scope.$index, dataInfoList)"
                >移除</el-button
              >
            </template>
          </el-table-column>
          <el-table-column label="标题" prop="title" width="120">
            <template slot-scope="scope">
              <template>
                <el-input
                  class="edit-input"
                  v-model="scope.row.title"
                  placeholder="请输入内容"
                />
              </template>
            </template>
          </el-table-column>

          <el-table-column label="内容" prop="content" width="120">
            <template slot-scope="scope">
              <template>
                <el-input
                  class="edit-input"
                  v-model="scope.row.content"
                  placeholder="内容"
                />
              </template>
            </template>
          </el-table-column>

          <el-table-column label="等级" prop="grade" width="100px">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.grade"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in gradeOption"
                  :key="item.key"
                  :label="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column
            label="预算"
            prop="budget"
            align="center"
            width="120px"
          >
            <template slot-scope="scope">
              <el-input-number
                class="edit-input"
                v-model="scope.row.budget"
                placeholder="预算"
                controls-position="right"
                style="width: 100%"
              />
            </template>
          </el-table-column>

          <el-table-column
            label="排序"
            prop="sequence"
            align="center"
            width="120px"
          >
            <template slot-scope="scope">
              <el-input-number
                class="edit-input"
                v-model="scope.row.sequence"
                placeholder="排序"
                controls-position="right"
                style="width: 100%"
              />
            </template>
          </el-table-column>

          <el-table-column label="备注" prop="remark" width="120">
            <template slot-scope="scope">
              <template>
                <el-input
                  class="edit-input"
                  v-model="scope.row.remark"
                  placeholder="备注"
                />
              </template>
            </template>
          </el-table-column>
          <!-- <el-table-column label="url" prop="url" width="120">
            <template slot-scope="scope">
              <template>
                <el-input class="edit-input" v-model="scope.row.url" placeholder="url" />
              </template>
            </template>
          </el-table-column> -->

          <el-table-column label="状态" prop="status" width="150">
            <template slot-scope="scope">
              <template>
                <el-select
                  v-model="scope.row.status"
                  filterable
                  placeholder="请选择"
                  style="width: 70%"
                >
                  <el-option
                    v-for="item in infoData"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key"
                  ></el-option>
                </el-select>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="进度" prop="progressRate" width="120">
            <template slot-scope="scope">
              <el-slider v-model="scope.row.progressRate" show-stops>
              </el-slider>
            </template>
          </el-table-column>
        </el-table>

        <div class="demo-drawer__footer">
          <el-button @click="cancelForm">取 消</el-button>
          <el-button type="primary" @click="addFormButton()">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addplanApi,
  selectplanByIdApi,
  editplanApi,
} from "@/api/work/plan/plan.js";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import customProgress from "@/views/otherUnits/customProgress.vue";
export default {
  components: {
    customProgress
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dialog: false,
      loading: false,
      editShow: false,
      title: "定时任务",
      addEditForm: {
        status: 1,
      },
      timer: null,
      ids: null,
      planType: 1,
      infoData: [
        {
          key: 1,
          value: "正常",
        },
      ],
      dataInfoShow: false,
      dataInfoList: [],
      caType: 1,
      planDayList: [],
      gradeOption: [],
      inputVisible: false,
      dynamicTags: [],
    };
  },
  methods: {
    show(id, type, planType) {
      this.dialog = true;
      this.planType = planType;
      this.initEnumList();
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "循环任务";
      }
      this.caType = type;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.dataInfoList = [];
      this.$refs["addEditForm"].resetFields();
      this.$parent.listplanButton();
    },
    addFormButton() {
      this.addEditForm.label = this.dynamicTags.toString();
      this.addEditForm.xunPlanInfoReqVOS = this.dataInfoList;
      this.addEditForm.category = this.planType;
      this.addEditForm.day = this.addEditForm.day.toString();
      if (this.addEditForm.time != undefined) {
        this.addEditForm.planStartTime = this.addEditForm.time[0];
        this.addEditForm.planEndTime = this.addEditForm.time[1];
      }

      if (this.caType == 1) {
        addplanApi(this.addEditForm).then((res) => {
          this.cancelForm();
        });
      } else {
        editplanApi(this.addEditForm).then((res) => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton(id) {
      selectplanByIdApi(id).then((res) => {
        this.addEditForm = res.result;
        this.addEditForm.day = res.result.day.split(",");
        if (res.result.isDataInfo == 1) {
          this.dataInfoShow = true;
          this.dataInfoList = res.result.xunPlanInfoReqVOS;
        }
        var times = [];
        times.unshift(
          this.addEditForm.planStartTime,
          this.addEditForm.planEndTime
        );
        this.addEditForm.time = times;
        this.dynamicTags = res.result.label.split(",");
        this.addEditForm.label = null;
        console.log(times);
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    isDataInfoButton() {
      console.log("1==", this.addEditForm.isDataInfo);
      if (this.addEditForm.isDataInfo == 1) {
        this.dataInfoShow = true;
      } else {
        this.dataInfoShow = false;
        this.dataInfoList = [];
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addDataInfo() {
      var dataOneInfo = {
        title: "",
      };
      this.dataInfoList.push(dataOneInfo);
    },
    initEnumList() {
      getWorkEnumList({ enumName: "PlanDayEnum" }).then((res) => {
        this.planDayList = res.result;
      });
      getWorkEnumList({ enumName: "PlanGradeEnum" }).then((res) => {
        this.gradeOption = res.result;
      });
    },
    handleCloseTag(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.addEditForm.label;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      console.log(this.dynamicTag);
      this.inputVisible = false;
      this.addEditForm.label = "";
    },
    onPercentChange(num) {
      this.addEditForm.progressRate = num;
    },
  },
};
</script>
<style scoped>
</style>



